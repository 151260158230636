import { useState } from "react";

const AboutUs = () => {
  const [ isFirstVisit, setIsFirstVisit] = useState(true)

    
  if (isFirstVisit) {
      window.scrollTo(0, 0);
      setIsFirstVisit(false)
  }
  return (
    <div>
      about us page
    </div>
  )
}

export default AboutUs
