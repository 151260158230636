import React, { useEffect, useState } from 'react'
import InvoiceTable from '../common/InvoiceTable'
import Loading from '../common/Loading'
import axios from 'axios'
import DescriptionAlerts from '../common/Alert';
import { Box, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const Invoice = () => {
    const [isFirstVisit, setIsFirstVisit] = useState(true)
    const navigate = useNavigate()
    const [invoices, setInvoice] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);


    if (isFirstVisit) {
        window.scrollTo(0, 0);
        setIsFirstVisit(false)
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        const searchQueryLowerCase = searchQuery.toLowerCase();
        // Filter invoices based on the search query
        const filteredInvoices = invoices.filter((invoice) =>
            invoice.invoiceNo.toLowerCase().includes(searchQueryLowerCase)
        );

        setSearchResults(filteredInvoices);
    }, [searchQuery])


    useEffect(() => {
        const token = localStorage.getItem('token')
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        axios.get(`${process.env.REACT_APP_API_URL}/invoice`, { headers })
            .then((response) => {
                setInvoice(response?.data)
                setIsLoading(false)
                console.log(response)
            })
            .catch((error) => {
                setSeverity('error')
                setAlertMessage(error?.response)
                setOpenSnackbar(true);
                if (error?.response?.status === 401) {
                    navigate('/')
                }
            })
    }, [navigate])

    if (isLoading) {
        return <Loading />
    }

    return (
        <Box sx={{ backgroundColor: '#eee', pt: '30px', pb: '30px' }}>
            <DescriptionAlerts openSnackbar={openSnackbar} handleCloseSnackbar={handleCloseSnackbar} severity={severity} alertMessage={alertMessage} />
            <TextField
                id="filled-search"
                label="Search invoices..."
                type="search"
                variant="standard"
                value={searchQuery}
                onChange={handleSearchInputChange}
                sx={{ backgroundColor: 'white', mt: '30px', ml: '30px' }}
            />
            <InvoiceTable invoices={searchQuery ? searchResults : invoices} />

        </Box>
    )
}

export default Invoice
