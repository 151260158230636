import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, DialogActions, TextField, Button, DialogContent, DialogContentText, DialogTitle, Dialog, Radio, FormControlLabel, RadioGroup, FormLabel, FormControl } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DescriptionAlerts from './Alert';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ApplicationTable = ({ applications }) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [newStatus, setNewStatus] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [selectedApplication, setSelectedApplication] = useState([])
    const [repairCost,setRepairCost] = useState('')
    const [paymentStatus,setPaymentStatus] = useState('Pending')

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const editButtonClicked = (application) => {
        setSelectedApplication(application)
        handleClickOpen()
    }


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setNewStatus('')
        setSelectedApplication([])
    };

    const handleAddClose = () => {
        setOpenAdd(false);
        setRepairCost('')
        setPaymentStatus('Pending')
        setSelectedApplication([])
    };

    const handleOpenDelete = () => {
        setDeleteDialogOpen(true)
    }

    const handleCloseDelete = () => {
        setDeleteDialogOpen(false)
        setDeleteId('')
    }

    const deleteButtonClicked = (id) => {
        setDeleteId(id)
    }

    useEffect(() => {
        if (deleteId) {
            handleOpenDelete()
        }
    }, [deleteId])

    const handleStatus = (e) => {
        setNewStatus(e.target.value)
    }

    const handleUpdate = () => {
        const token = localStorage.getItem('token')
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        axios.put(`${process.env.REACT_APP_API_URL}/application/update/${selectedApplication._id}`, {
            customerName: selectedApplication.customerName,
            email: selectedApplication.email,
            phoneNumber: selectedApplication.phoneNumber,
            brand: selectedApplication.brand,
            brandName: selectedApplication.brandName,
            model: selectedApplication.model,
            modelName: selectedApplication.modelName,
            partsDetails: selectedApplication.partsDetails,
            status: newStatus
        }, { headers })
            .then((response) => {
                console.log(response)
                setNewStatus('')
                setSelectedApplication([])
                handleClose()
            }).catch((err) => {
                console.log(err)
                if (err?.response?.status === 401) {
                    setTimeout(() => {
                        navigate('/')
                    }, 1000);
                }
            })
    }

    const handleDelete = () => {
        const token = localStorage.getItem('token')
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        axios.delete(`${process.env.REACT_APP_API_URL}/application/delete/${deleteId}`, { headers })
            .then((response) => {
                setDeleteId(false)
                handleCloseDelete()
                console.log(response)
                if (response.status === 200) {
                    setSeverity('success')
                    setAlertMessage('Deleted sucessfully')
                    setOpenSnackbar(true);
                }
            }).catch((err) => {
                console.log(err)
                setSeverity('error')
                setAlertMessage(err?.response?.data?.message)
                setOpenSnackbar(true);
                if (err?.response?.status === 401) {
                    setTimeout(() => {
                        navigate('/')
                    }, 1000);
                }
            })
    }

    const createInvoice = (application) => {
        setSelectedApplication(application)
        setOpenAdd(true)
    }

    const handleRepairCost = (e) =>{
        setRepairCost(e.target.value)
    }

    const handlePaymentStatus = (e) =>{
        setPaymentStatus(e.target.value)
    }

    const handleCreateInvoice = () =>{
        const token = localStorage.getItem('token')
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        axios.post(`${process.env.REACT_APP_API_URL}/invoice/create`, {
            application: selectedApplication,
            repairCost: parseFloat(repairCost),
            paymentStatus:paymentStatus
        }, { headers })
            .then((response) => {
                handleAddClose()
                setSeverity('success')
                setAlertMessage('Invoice created sucessfully')
                setOpenSnackbar(true);
            }).catch((err) => {
                setSeverity('error')
                setAlertMessage(err?.response?.data?.error)
                setOpenSnackbar(true);
                console.log(err)
                if (err?.response?.status === 401) {
                    setTimeout(() => {
                        navigate('/')
                    }, 1000);
                }
            })
    }

    return (
        <Box>
            <DescriptionAlerts openSnackbar={openSnackbar} handleCloseSnackbar={handleCloseSnackbar} severity={severity} alertMessage={alertMessage} />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '30px', mb: '30px', ml: '30px', mr: '30px' }}>
                <TableContainer component={Paper} align='center'>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Customer Name</StyledTableCell>
                                <StyledTableCell align="left">Email</StyledTableCell>
                                <StyledTableCell align="left">Phone Number</StyledTableCell>
                                <StyledTableCell align="left">Brand Name</StyledTableCell>
                                <StyledTableCell align="left">Model Name</StyledTableCell>
                                <StyledTableCell align="left">Parts</StyledTableCell>
                                <StyledTableCell align="left">Status</StyledTableCell>
                                <StyledTableCell align="left">Create Invoice</StyledTableCell>
                                <StyledTableCell align="letf">Delete</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {applications.map((application) => (
                                <StyledTableRow key={application._id} sx={{
                                    ":hover": { backgroundColor: '#666' }, "&.custom-row": {
                                        backgroundColor:
                                            application.status === 'ToDo' ? 'orange' :
                                                application.status === 'Done' ? '#50C878' :
                                                    '#eee',
                                    },
                                }}
                                    className="custom-row">
                                    <StyledTableCell component="th" scope="row">
                                        {application.customerName}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {application.email}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {application.phoneNumber}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {application.brandName}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {application.modelName}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {application.partsDetails.map((part) => (
                                            <div key={part.parts}>
                                                Name: {part.name}, <br />Price: {part.price}
                                            </div>
                                        ))}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        <Button sx={{ color: 'black', ':hover': { backgroundColor: 'black', color: 'white' } }}
                                            onClick={() => editButtonClicked(application)}>
                                            {application.status}
                                        </Button>
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        <Button sx={{ color: 'black', ':hover': { backgroundColor: 'black', color: 'white' } }}
                                            onClick={() => createInvoice(application)}
                                            disabled={application.status !== 'Done'}>
                                            <AddCircleOutlineIcon />
                                        </Button>
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row"><DeleteIcon sx={{
                                        borderRadius: '5px',
                                        width: '40px',
                                        height: '30px',
                                        ":hover": {
                                            backgroundColor: 'black',
                                            color: 'white'
                                        }
                                    }} onClick={() => deleteButtonClicked(application._id)} /></StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>


            <Box>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle sx={{ backgroundColor: 'black', color: 'white', textAlign: 'center' }}>Update Status</DialogTitle>
                    <DialogContent sx={{ mt: '10px' }}>
                        <FormControl sx={{ mt: '10px' }}>
                            <FormLabel id="radio-buttons-group-label">Status</FormLabel>
                            <RadioGroup
                                onChange={handleStatus}
                                defaultValue={selectedApplication.status}
                            >
                                <FormControlLabel value={'ToDo'} control={<Radio />} label='ToDo' />
                                <FormControlLabel value={'InProgress'} control={<Radio />} label='InProgress' />
                                <FormControlLabel value={'Done'} control={<Radio />} label='Done' />
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={{
                                backgroundColor: 'black',
                                width: '30%',
                                color: 'white',
                                ":hover": {
                                    backgroundColor: 'red',
                                    color: 'white',
                                    cursor: 'pointer'
                                },
                            }} onClick={handleClose}>Cancel</Button>
                        <Button
                            sx={{
                                backgroundColor: 'black',
                                color: 'white',
                                width: '70%',
                                ":hover": {
                                    backgroundColor: '#50C878',
                                    color: 'white',
                                    cursor: 'pointer'
                                },
                            }}
                            onClick={handleUpdate}
                        >Update Status</Button>
                    </DialogActions>
                </Dialog>
            </Box>



            <Box>
                <Dialog open={deleteDialogOpen} onClose={handleCloseDelete}>
                    <DialogContent sx={{ mt: '10px', }}>
                        <DialogContentText >
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                            </Box>
                            Are you sure!
                            Do you want to delete it?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={{
                                backgroundColor: 'black',
                                width: '50%',
                                color: 'white',
                                ":hover": {
                                    backgroundColor: 'red',
                                    color: 'white',
                                    cursor: 'pointer'
                                },
                            }} onClick={handleCloseDelete}>No! Don't</Button>
                        <Button
                            sx={{
                                backgroundColor: 'black',
                                color: 'white',
                                width: '50%',
                                ":hover": {
                                    backgroundColor: '#50C878',
                                    color: 'white',
                                    cursor: 'pointer'
                                },
                            }}
                            onClick={handleDelete}
                        >yes, please</Button>
                    </DialogActions>
                </Dialog>
            </Box>

            <Box>
                <Dialog open={openAdd} onClose={handleAddClose}>
                    <DialogTitle sx={{ backgroundColor: 'black', color: 'white', textAlign: 'center' }}>Create Invoice</DialogTitle>
                    <DialogContent sx={{ mt: '10px' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Repair Cost"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleRepairCost}
                        />
                        <FormControl sx={{ mt: '10px' }}>
                            <FormLabel id="radio-buttons-group-label">Type</FormLabel>
                            <RadioGroup
                                onChange={handlePaymentStatus}
                                defaultValue={paymentStatus}
                            >
                                <FormControlLabel value={'Pending'} control={<Radio />} label='Pending' />
                                <FormControlLabel value={'Completed'} control={<Radio />} label='Completed' />
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={{
                                backgroundColor: 'black',
                                width: '50%',
                                color: 'white',
                                ":hover": {
                                    backgroundColor: 'red',
                                    color: 'white',
                                    cursor: 'pointer'
                                },
                            }} onClick={handleAddClose}>Cancel</Button>
                        <Button
                            sx={{
                                backgroundColor: 'black',
                                color: 'white',
                                width: '50%',
                                ":hover": {
                                    backgroundColor: '#50C878',
                                    color: 'white',
                                    cursor: 'pointer'
                                },
                            }}
                            onClick={handleCreateInvoice}
                        >Create Invoice</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}


export default ApplicationTable
