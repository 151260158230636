import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, TextField, DialogActions, Button, DialogContent, DialogContentText, DialogTitle, Dialog } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import axios from 'axios';
import DescriptionAlerts from './Alert';
import { useNavigate } from 'react-router-dom';




const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const PartTable = ({ model }) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [editpartId, setEditpartId] = useState('')
    const [updatedName, setUpdatedName] = useState('')
    const [updatedPrice, setUpdatedPrice] = useState('')
    const [updatedQuantity, setUpdatedQuantity] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [parts, setParts] = useState([])
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };


    useEffect(() => {
        setParts(model.parts)
    }, [model])

    const editButtonClicked = (part) => {
        setEditpartId(part._id)
        setUpdatedName(part.name)
        setUpdatedPrice(part.price)
        setUpdatedQuantity(part.noOfStocks)
        // setUpdatedParts(part.parts)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEditpartId('')
        setUpdatedName('')
        setUpdatedPrice('')
        setParts(model.parts)
    };

    const handleOpenDelete = () => {
        setDeleteDialogOpen(true)
    }

    const handleCloseDelete = () => {
        setDeleteDialogOpen(false)
        setDeleteId('')
    }

    const deleteButtonClicked = (id) => {
        setDeleteId(id)
    }

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        const searchQueryLowerCase = searchQuery.toLowerCase();
        // Filter parts based on the search query
        const filteredBrands = parts.filter((part) =>
            part.name.toLowerCase().includes(searchQueryLowerCase)
        );
        setSearchResults(filteredBrands);
    }, [searchQuery])

    useEffect(() => {
        if (deleteId) {
            handleOpenDelete()
        }
    }, [deleteId])

    useEffect(() => {
        if (editpartId) {
            handleClickOpen();
        }
    }, [editpartId]);

    const handlepartName = (e) => {
        setUpdatedName(e.target.value)
    }

    const handlepartPrice = (e) => {
        setUpdatedPrice(e.target.value)
    }

    const handlepartQuantity = (e) => {
        setUpdatedQuantity(e.target.value)
    }

    const handleUpdate = () => {
        const token = localStorage.getItem('token')
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        axios.put(`${process.env.REACT_APP_API_URL}/model/updatepart/${model._id}/${editpartId}`, {
            name: updatedName,
            price: updatedPrice,
            noOfStocks: updatedQuantity
        }, { headers })
            .then((response) => {
                console.log(response);
                setEditpartId('');
                setUpdatedPrice('');
                setUpdatedName('');
                handleClose();
                setSeverity('success')
                setAlertMessage(response.data.message)
                setOpenSnackbar(true);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
            .catch((err) => {
                console.log(err);
                setSeverity('error')
                setAlertMessage(err?.response?.data?.message)
                setOpenSnackbar(true);
                if (err?.response?.status === 401) {
                    setTimeout(() => {
                        navigate('/')
                    }, 1000);
                }
            });
    }

    const handleDelete = () => {
        const token = localStorage.getItem('token')
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        axios.delete(`${process.env.REACT_APP_API_URL}/model/deletepart/${model._id}/${deleteId}`, { headers })
            .then((response) => {
                console.log(response);
                setDeleteId('')
                handleCloseDelete();
                setAlertMessage(response.data.message)
                setOpenSnackbar(true);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
            .catch((err) => {
                console.log(err);
                setSeverity('error')
                setAlertMessage(err?.response?.data?.message)
                setOpenSnackbar(true);
                if (err?.response?.status === 401) {
                    setTimeout(() => {
                        navigate('/')
                    }, 1000);
                }
            });
    }

    return (
        <Box>
            <TextField
                id="filled-search"
                label="Search parts..."
                type="search"
                variant="standard"
                value={searchQuery}
                onChange={handleSearchInputChange}
                sx={{ backgroundColor: 'white', mt: '15px', ml: '30px' }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '30px', mb: '30px', ml: '30px', mr: '30px' }}>
                <DescriptionAlerts openSnackbar={openSnackbar} handleCloseSnackbar={handleCloseSnackbar} severity={severity} alertMessage={alertMessage} />
                <TableContainer component={Paper} align='center'>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Part Name</StyledTableCell>
                                <StyledTableCell align="left">Price</StyledTableCell>
                                <StyledTableCell align="left">Quantity</StyledTableCell>
                                <StyledTableCell align="left">Edit</StyledTableCell>
                                <StyledTableCell align="left">Delete</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {searchQuery ? searchResults.map((part) => (
                                <StyledTableRow
                                    key={part._id}
                                    sx={{ ":hover": { backgroundColor: '#666' } }}
                                >
                                    <StyledTableCell component="th" scope="row">
                                        {part.name}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {part.price}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {part.noOfStocks}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <EditIcon
                                            sx={{
                                                borderRadius: '50%',
                                                width: '40px',
                                                height: '30px',
                                                ":hover": { backgroundColor: 'black', color: 'white' },
                                            }}
                                            onClick={() => editButtonClicked(part)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <DeleteIcon
                                            sx={{
                                                borderRadius: '50%',
                                                width: '40px',
                                                height: '30px',
                                                ":hover": { backgroundColor: 'black', color: 'white' },
                                            }}
                                            onClick={() => deleteButtonClicked(part._id)}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            )) : parts.map((part) => (
                                <StyledTableRow
                                    key={part._id}
                                    sx={{ ":hover": { backgroundColor: '#666' } }}
                                >
                                    <StyledTableCell component="th" scope="row">
                                        {part.name}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {part.price}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {part.noOfStocks}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <EditIcon
                                            sx={{
                                                borderRadius: '50%',
                                                width: '40px',
                                                height: '30px',
                                                ":hover": { backgroundColor: 'black', color: 'white' },
                                            }}
                                            onClick={() => editButtonClicked(part)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <DeleteIcon
                                            sx={{
                                                borderRadius: '50%',
                                                width: '40px',
                                                height: '30px',
                                                ":hover": { backgroundColor: 'black', color: 'white' },
                                            }}
                                            onClick={() => deleteButtonClicked(part._id)}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>




            <Box>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle sx={{ backgroundColor: 'black', color: 'white', textAlign: 'center' }}>Update part</DialogTitle>
                    <DialogContent sx={{ mt: '10px' }}>
                        <DialogContentText >
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="part name"
                            type="text"
                            fullWidth
                            variant="standard"
                            defaultValue={updatedName}
                            onChange={handlepartName}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="email"
                            label="Price"
                            type="text"
                            fullWidth
                            variant="standard"
                            defaultValue={updatedPrice}
                            onChange={handlepartPrice}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="quantity"
                            label="Quantity"
                            type="text"
                            fullWidth
                            variant="standard"
                            defaultValue={updatedQuantity}
                            onChange={handlepartQuantity}
                        />
                        {/* {updatedParts.map((part)=>(
                            <Box key={part._id}>
                                <TextField
                            autoFocus
                            margin="dense"
                            label="Part Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            defaultValue={part.name}
                            onChange={handlepartPrice}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Part Price"
                            type="number"
                            fullWidth
                            variant="standard"
                            defaultValue={part.price}
                            onChange={handlepartPrice}
                        />
                            </Box>
                        ))} */}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={{
                                backgroundColor: 'black',
                                width: '50%',
                                color: 'white',
                                ":hover": {
                                    backgroundColor: 'red',
                                    color: 'white',
                                    cursor: 'pointer'
                                },
                            }} onClick={handleClose}>Cancel</Button>
                        <Button
                            sx={{
                                backgroundColor: 'black',
                                color: 'white',
                                width: '50%',
                                ":hover": {
                                    backgroundColor: '#50C878',
                                    color: 'white',
                                    cursor: 'pointer'
                                },
                            }}
                            onClick={handleUpdate}
                        >Update</Button>
                    </DialogActions>
                </Dialog>
            </Box>


            <Box>
                <Dialog open={deleteDialogOpen} onClose={handleCloseDelete}>
                    <DialogContent sx={{ mt: '10px', }}>
                        <DialogContentText >
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                            </Box>
                            Are you sure!
                            Do you want to delete it?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={{
                                backgroundColor: 'black',
                                width: '50%',
                                color: 'white',
                                ":hover": {
                                    backgroundColor: 'red',
                                    color: 'white',
                                    cursor: 'pointer'
                                },
                            }} onClick={handleCloseDelete}>No! Don't</Button>
                        <Button
                            sx={{
                                backgroundColor: 'black',
                                color: 'white',
                                width: '50%',
                                ":hover": {
                                    backgroundColor: '#50C878',
                                    color: 'white',
                                    cursor: 'pointer'
                                },
                            }}
                            onClick={handleDelete}
                        >yes, please</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

export default PartTable
