import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import ResponsiveAppBar from "./components/NavBar";
import Footer from "./components/Footer";
import Brands from "./pages/Brands";
import Models from "./pages/Models";
import Parts from "./pages/Parts";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Invoice from "./pages/Invoice";

function App() {

  return (
    <BrowserRouter>
      <ResponsiveAppBar />
      <Routes>
        <Route index path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/:brandType" element={<Brands />} />
        <Route path="/model/:id/:brandType" element={<Models />} />
        <Route path="/parts/:id" element={<Parts />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="*" element={<Navigate to='/'/>}/>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
