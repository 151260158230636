import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, Box, TextField, DialogActions, Button, DialogContent, DialogContentText, DialogTitle, Dialog } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useNavigate } from 'react-router-dom';
import DescriptionAlerts from './Alert';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ModelTable = ({ models, brandType }) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [editmodelId, setEditmodelId] = useState('')
    const [updatedName, setUpdatedName] = useState('')
    const [updatedImage, setUpdatedImage] = useState('')
    const [updatedBrand, setUpdatedBrand] = useState('')
    const [deleteId, setDeleteId] = useState('')
    // const [updatedParts,setUpdatedParts] = useState([])
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [brands, setBrands] = useState([])
    const [parts, setParts] = useState([])
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('')
    const [alertMessage, setAlertMessage] = useState('')

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/brand/${brandType}`)
            .then((response) => {
                setBrands(response.data)
            })
            .catch((error) => {
                setSeverity('error')
                setAlertMessage('Can not load the brands')
                setOpenSnackbar(true);
            })
    }, [brandType])

    const editButtonClicked = (model) => {
        setEditmodelId(model._id)
        setUpdatedName(model.name)
        setUpdatedImage(model.image)
        setUpdatedBrand(model.brand._id)
        setParts(model.parts)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEditmodelId('')
        setUpdatedName('')
        setUpdatedImage('')
    };

    const handleOpenDelete = () => {
        setDeleteDialogOpen(true)
    }

    const handleCloseDelete = () => {
        setDeleteDialogOpen(false)
        setDeleteId('')
    }

    const deleteButtonClicked = (id) => {
        setDeleteId(id)
    }

    useEffect(() => {
        if (deleteId) {
            handleOpenDelete()
        }
    }, [deleteId])

    useEffect(() => {
        if (editmodelId) {
            handleClickOpen();
        }
    }, [editmodelId]);

    const handlemodelName = (e) => {
        setUpdatedName(e.target.value)
    }

    const handlemodelImage = (e) => {
        setUpdatedImage(e.target.value)
    }

    const handlemodelBrand = (e) => {
        setUpdatedBrand(e.target.value)
        console.log(updatedBrand)
    }


    const handleUpdate = () => {
        const token = localStorage.getItem('token')
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        axios.put(`${process.env.REACT_APP_API_URL}/model/updatemodel/${editmodelId}`, {
            id: editmodelId,
            name: updatedName,
            image: updatedImage,
            brand: updatedBrand,
            parts: parts

        }, { headers })
            .then((response) => {
                console.log(response)
                setEditmodelId('')
                setUpdatedImage('')
                setUpdatedName('')
                handleClose()
                setSeverity('success')
                setAlertMessage(response.data.message)
                setOpenSnackbar(true);
            }).catch((err) => {
                console.log(err)
                setSeverity('error')
                setAlertMessage(err?.response?.data?.message)
                setOpenSnackbar(true);
                if (err?.response?.status === 401) {
                    setTimeout(() => {
                        navigate('/')
                    }, 1000);
                }
            })
    }

    const handleDelete = () => {
        const token = localStorage.getItem('token')
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        axios.delete(`${process.env.REACT_APP_API_URL}/model/deletemodel/${deleteId}`, { headers })
            .then((response) => {
                setDeleteId(false)
                handleCloseDelete()
                console.log(response)
                setSeverity('success')
                setAlertMessage(response?.data?.message)
                setOpenSnackbar(true);
            }).catch((err) => {
                console.log(err)
                setSeverity('error')
                setAlertMessage(err?.response?.data?.message)
                setOpenSnackbar(true);
                if (err?.response?.status === 401) {
                    setTimeout(() => {
                        navigate('/')
                    }, 1000);
                }
            })
    }

    const displayParts = (id) => {
        navigate(`/parts/${id}`)
    }
    return (
        <Box>
            <DescriptionAlerts openSnackbar={openSnackbar} handleCloseSnackbar={handleCloseSnackbar} severity={severity} alertMessage={alertMessage} />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '30px', mb: '30px', ml: '30px', mr: '30px' }}>
                <TableContainer component={Paper} align='center'>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Model Name</StyledTableCell>
                                <StyledTableCell align="left">Model Image</StyledTableCell>
                                <StyledTableCell align="left">Parts</StyledTableCell>
                                <StyledTableCell align="left">Edit</StyledTableCell>
                                <StyledTableCell align="left">Delete</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {models.map((model) => (
                                <StyledTableRow
                                    key={model._id}
                                    sx={{ ":hover": { backgroundColor: '#666' } }}
                                >
                                    <StyledTableCell component="th" scope="row">
                                        {model.name}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ height: '20%', width: '40%' }} align="left">
                                        <Avatar
                                            alt=""
                                            src={model.image}
                                            sx={{ width: 90, height: 90, position: 'top' }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="left"><ReadMoreIcon sx={{
                                        borderRadius: '50%',
                                        width: '40px',
                                        height: '30px',
                                        ":hover": {
                                            backgroundColor: 'black',
                                            color: 'white'
                                        }
                                    }} onClick={() => displayParts(model._id)} /></StyledTableCell>
                                    <StyledTableCell align="left">
                                        <EditIcon
                                            sx={{
                                                borderRadius: '50%',
                                                width: '40px',
                                                height: '30px',
                                                ":hover": { backgroundColor: 'black', color: 'white' },
                                            }}
                                            onClick={() => editButtonClicked(model)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <DeleteIcon
                                            sx={{
                                                borderRadius: '50%',
                                                width: '40px',
                                                height: '30px',
                                                ":hover": { backgroundColor: 'black', color: 'white' },
                                            }}
                                            onClick={() => deleteButtonClicked(model._id)}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>




            <Box>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle sx={{ backgroundColor: 'black', color: 'white', textAlign: 'center' }}>Update model</DialogTitle>
                    <DialogContent sx={{ mt: '10px' }}>
                        <DialogContentText >
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Avatar
                                    alt=""
                                    src={updatedImage}
                                    sx={{ width: 90, height: 90, backgroundColor: 'black', position: 'top' }}
                                />
                            </Box>
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="model name"
                            type="text"
                            fullWidth
                            variant="standard"
                            defaultValue={updatedName}
                            onChange={handlemodelName}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="email"
                            label="Image URL"
                            type="text"
                            fullWidth
                            variant="standard"
                            defaultValue={updatedImage}
                            onChange={handlemodelImage}
                        />
                        <FormControl sx={{ mt: '15px' }}>
                            <FormLabel id="demo-radio-buttons-group-label">Brand</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                onChange={handlemodelBrand}
                            >
                                {brands.map((brand) => (
                                    <FormControlLabel key={brand._id} value={brand._id} control={<Radio />} label={brand.name} />
                                ))}
                            </RadioGroup>
                        </FormControl>
                        {/* {updatedParts.map((part)=>(
                            <Box key={part._id}>
                                <TextField
                            autoFocus
                            margin="dense"
                            label="Part Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            defaultValue={part.name}
                            onChange={handlemodelImage}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Part Price"
                            type="number"
                            fullWidth
                            variant="standard"
                            defaultValue={part.price}
                            onChange={handlemodelImage}
                        />
                            </Box>
                        ))} */}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={{
                                backgroundColor: 'black',
                                width: '50%',
                                color: 'white',
                                ":hover": {
                                    backgroundColor: 'red',
                                    color: 'white',
                                    cursor: 'pointer'
                                },
                            }} onClick={handleClose}>Cancel</Button>
                        <Button
                            sx={{
                                backgroundColor: 'black',
                                color: 'white',
                                width: '50%',
                                ":hover": {
                                    backgroundColor: '#50C878',
                                    color: 'white',
                                    cursor: 'pointer'
                                },
                            }}
                            onClick={handleUpdate}
                        >Update</Button>
                    </DialogActions>
                </Dialog>
            </Box>


            <Box>
                <Dialog open={deleteDialogOpen} onClose={handleCloseDelete}>
                    <DialogContent sx={{ mt: '10px', }}>
                        <DialogContentText >
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                            </Box>
                            Are you sure!
                            Do you want to delete it?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={{
                                backgroundColor: 'black',
                                width: '50%',
                                color: 'white',
                                ":hover": {
                                    backgroundColor: 'red',
                                    color: 'white',
                                    cursor: 'pointer'
                                },
                            }} onClick={handleCloseDelete}>No! Don't</Button>
                        <Button
                            sx={{
                                backgroundColor: 'black',
                                color: 'white',
                                width: '50%',
                                ":hover": {
                                    backgroundColor: '#50C878',
                                    color: 'white',
                                    cursor: 'pointer'
                                },
                            }}
                            onClick={handleDelete}
                        >yes, please</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

export default ModelTable
