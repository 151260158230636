import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import theme from '../common/Theme';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';


function ResponsiveAppBar() {
  const navigate = useNavigate()
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token')
    navigate('/')
  }


  return (
    <AppBar position="static" sx={{ height: '80px', background: theme.palette.navbar.main, boxShadow: '0 .5rem 1.5rem rgba(0,0,0,.1)' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <IconButton
              size="large"
              aria-haspopup="true"
              aria-controls="menu-appbar"   //"inherit"
              onClick={handleOpenNavMenu}
            ><MenuIcon sx={{ fotSize: 32 }} /></IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem component={Link} to='/home' onClick={handleCloseNavMenu}>
                <Typography textAlign="center">Home</Typography>
              </MenuItem>
              <MenuItem component={Link} to={`/${'mobile'}`} onClick={handleCloseNavMenu}>
                <Typography textAlign="center">Mobile</Typography>
              </MenuItem>
              <MenuItem component={Link} to={`/${'tablet'}`} onClick={handleCloseNavMenu}>
                <Typography textAlign="center">Tablet </Typography>
              </MenuItem>
              <MenuItem component={Link} to={`/${'ipad'}`} onClick={handleCloseNavMenu}>
                <Typography textAlign="center">I Pad</Typography>
              </MenuItem>
              <MenuItem component={Link} to={`/${'laptop'}`} onClick={handleCloseNavMenu}>
                <Typography textAlign="center">Labtop</Typography>
              </MenuItem>
              <MenuItem component={Link} to={`/${'computer'}`} onClick={handleCloseNavMenu}>
                <Typography textAlign="center">Computer</Typography>
              </MenuItem>
              <MenuItem component={Link} to={`/${'invoice'}`} onClick={handleCloseNavMenu}>
                <Typography textAlign="center">Invoice</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <AppSettingsAltIcon sx={{ color: 'black', fotSize: 32, display: { xs: 'flex', lg: 'none' }, mr: 1 }} />
          <Typography
            variant="h4"
            noWrap
            component="a"
            href="/"
            sx={{
              display: { xs: 'flex', lg: 'none' },
              fontFamily: 'monospace',
              fontWeight: 500,
              letterSpacing: '.2rem',
              flexGrow: 1,
              color: theme.palette.letter.main,
              textDecoration: 'none',
            }}
          >
            THR
          </Typography>
          <AppSettingsAltIcon sx={{ display: { xs: 'none', md: 'none', lg: 'flex' }, mr: 1, fontSize: 42, color: theme.palette.letter.main }} />
          <Typography
            variant="h4"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'none', lg: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 500,
              letterSpacing: '.2rem',
              fontSize: '26px',
              color: theme.palette.letter.main,
              textDecoration: 'none',
              textTransform: 'capitalize',
            }}
          >
            TONIK HANDY REPARATUR
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button
              key='home'
              component={Link}
              to='/home'
              sx={{
                textTransform: 'capitalize',
                color: theme.palette.letter.main,
                height: '80px',
                fontSize: '13px',
                pr: '25px',
                pl: '25px',
                borderRadius: '0',
                ":hover": {
                  backgroundColor: 'black',
                  color: 'white'
                },
                ml: '5px'
              }}
            >
              Home
            </Button>
            <Button
              key='mobile'
              component={Link}
              to={`/${'mobile'}`}
              sx={{
                textTransform: 'capitalize',
                color: theme.palette.letter.main,
                height: '80px',
                fontSize: '13px',
                pr: '25px',
                pl: '25px',
                borderRadius: '0',
                ":hover": {
                  backgroundColor: 'black',
                  color: 'white'
                },
                ml: '5px'
              }}
            >
              Mobile
            </Button>
            <Button
              key='tablet'
              component={Link}
              to={`/${'tablet'}`}
              sx={{
                textTransform: 'capitalize',
                color: theme.palette.letter.main,
                height: '80px',
                fontSize: '13px',
                pr: '25px',
                pl: '25px',
                borderRadius: '0',
                ":hover": {
                  backgroundColor: 'black',
                  color: 'white'
                },
                ml: '5px'
              }}
            >
              Tablet
            </Button>
            <Button
              key='ipad'
              component={Link}
              to={`/${'ipad'}`}
              sx={{
                textTransform: 'capitalize',
                color: theme.palette.letter.main,
                height: '80px',
                fontSize: '13px',
                pr: '25px',
                pl: '25px',
                borderRadius: '0',
                ":hover": {
                  backgroundColor: 'black',
                  color: 'white'
                },
                ml: '5px'
              }}
            >
              I Pad
            </Button>
            <Button
              key='laptop'
              component={Link}
              to={`/${'laptop'}`}
              sx={{
                textTransform: 'capitalize',
                color: theme.palette.letter.main,
                height: '80px',
                fontSize: '13px',
                pr: '25px',
                pl: '25px',
                borderRadius: '0',
                ":hover": {
                  backgroundColor: 'black',
                  color: 'white'
                },
                ml: '5px'
              }}
            >
              Labtop
            </Button>
            <Button
              key='computer'
              component={Link}
              to={`/${'computer'}`}
              sx={{
                textTransform: 'capitalize',
                color: theme.palette.letter.main,
                height: '80px',
                fontSize: '13px',
                pr: '25px',
                pl: '25px',
                borderRadius: '0',
                ":hover": {
                  backgroundColor: 'black',
                  color: 'white'
                },
                ml: '5px'
              }}
            >
              Computer
            </Button>
            <Button
              key='invoice'
              component={Link}
              to='/invoice'
              sx={{
                textTransform: 'capitalize',
                color: theme.palette.letter.main,
                height: '80px',
                fontSize: '13px',
                pr: '25px',
                pl: '25px',
                borderRadius: '0',
                ":hover": {
                  backgroundColor: 'black',
                  color: 'white'
                },
                ml: '5px'
              }}
            >
              Invoices
            </Button>
            <Button
              key='contactus'
              component={Link}
              to='/contactus'
              sx={{
                textTransform: 'capitalize',
                color: theme.palette.letter.main,
                height: '80px',
                fontSize: '13px',
                pr: '25px',
                pl: '25px',
                borderRadius: '0',
                ":hover": {
                  backgroundColor: 'black',
                  color: 'white'
                },
                ml: '5px'
              }}
            >
              Contact Us
            </Button>
            <Button
              key='aboutus'
              component={Link}
              to='/aboutus'
              sx={{
                textTransform: 'capitalize',
                color: theme.palette.letter.main,
                height: '80px',
                fontSize: '13px',
                pr: '25px',
                pl: '25px',
                borderRadius: '0',
                ":hover": {
                  backgroundColor: 'black',
                  color: 'white'
                },
                ml: '5px'
              }}
            >
              About Us
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'flex' } }}>
              <IconButton onClick={handleLogout}>
                <LogoutIcon sx={{ fontSize: '32px' }} />
              </IconButton>
            </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;



