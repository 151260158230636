import React, { useEffect, useState } from 'react'
import BrandTable from '../common/BrandTable'
import Loading from '../common/Loading'
import axios from 'axios'
import AddIcon from '@mui/icons-material/Add';
import DescriptionAlerts from '../common/Alert';
import { Box, Button, TextField, DialogActions, DialogContent, DialogTitle, Dialog, RadioGroup, FormControlLabel, FormLabel, FormControl, Radio } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';


const Brands = () => {
    const [isFirstVisit, setIsFirstVisit] = useState(true)
    const navigate = useNavigate()
    const { brandType } = useParams()
    const [brands, setBrands] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [openAdd, setOpenAdd] = useState(false);
    const [brandName, setBrandName] = useState('')
    const [brandImage, setBrandImage] = useState('')
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [newBrandType, setNewBrandType] = useState('mobile')
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);



    if (isFirstVisit) {
        window.scrollTo(0, 0);
        setIsFirstVisit(false)
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/brand/${brandType}`)
            .then((response) => {
                setBrands(response.data)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setIsLoading(false)
                setSeverity('error')
                setAlertMessage('Network error')
                setOpenSnackbar(true);
            })
    }, [brandType])

    const handleAddOpen = () => {
        setOpenAdd(true)
        setNewBrandType(brandType)
    };

    const handleAddClose = () => {
        setOpenAdd(false);
        setBrandImage('')
        setBrandName('')
        setNewBrandType('')
    };

    const handleBrandName = (e) => {
        setBrandName(e.target.value)
    }

    const handleBrandImage = (e) => {
        setBrandImage(e.target.value)
    }

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        const searchQueryLowerCase = searchQuery.toLowerCase();
        // Filter brands based on the search query
        const filteredBrands = brands.filter((brand) =>
            brand.name.toLowerCase().includes(searchQueryLowerCase)
        );
        setSearchResults(filteredBrands);
    }, [searchQuery])

    const handleAddBrand = () => {
        console.log(newBrandType)
        const token = localStorage.getItem('token')
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        axios.post(`${process.env.REACT_APP_API_URL}/brand/addbrand`, {
            name: brandName,
            image: brandImage,
            brandType: newBrandType
        }, { headers })
            .then((response) => {
                handleAddClose()
                setSeverity('success')
                setAlertMessage('Brand Added sucessfully')
                setOpenSnackbar(true);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }).catch((err) => {
                setSeverity('error')
                setAlertMessage(err?.response?.data?.message)
                setOpenSnackbar(true);
                console.log(err)
                if (err?.response?.status === 401) {
                    setTimeout(() => {
                        navigate('/')
                    }, 1000);
                }
            })
    }

    const handleBrandType = (e) => {
        setNewBrandType(e.target.value)
    }

    if (isLoading) {
        return <Loading />
    }

    return (
        <Box sx={{ backgroundColor: '#eee', pt: '30px', pb: '30px' }}>
            <DescriptionAlerts openSnackbar={openSnackbar} handleCloseSnackbar={handleCloseSnackbar} severity={severity} alertMessage={alertMessage} />
            <Box sx={{ ml: '30px' }}>
                <Box sx={{ display: 'flex' }}>
                    <Button sx={{
                        backgroundColor: 'black', color: 'white',
                        ":hover": {
                            backgroundColor: '#50C878'
                        }
                    }}
                        onClick={handleAddOpen}>
                        <AddIcon />Add brand
                    </Button>
                    <TextField
                        id="filled-search"
                        label="Search brands..."
                        type="search"
                        variant="standard"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                        sx={{ backgroundColor: 'white', ml: '30px' }}
                    />
                </Box>

                <Box>
                    <Dialog open={openAdd} onClose={handleAddClose}>
                        <DialogTitle sx={{ backgroundColor: 'black', color: 'white', textAlign: 'center' }}>Add brand</DialogTitle>
                        <DialogContent sx={{ mt: '10px' }}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="brand name"
                                type="text"
                                fullWidth
                                variant="standard"
                                defaultValue={brandName}
                                onChange={handleBrandName}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="image"
                                label="Image URL"
                                type="text"
                                fullWidth
                                variant="standard"
                                defaultValue={brandImage}
                                onChange={handleBrandImage}
                            />
                            <FormControl sx={{ mt: '10px' }}>
                                <FormLabel id="radio-buttons-group-label">Type</FormLabel>
                                <RadioGroup
                                    onChange={handleBrandType}
                                    defaultValue={brandType}
                                >
                                    <FormControlLabel value={'mobile'} control={<Radio />} label='Mobile' />
                                    <FormControlLabel value={'tablet'} control={<Radio />} label='Tablet' />
                                    <FormControlLabel value={'ipad'} control={<Radio />} label='I Pad' />
                                    <FormControlLabel value={'laptop'} control={<Radio />} label='Laptop' />
                                    <FormControlLabel value={'computer'} control={<Radio />} label='Computer' />
                                </RadioGroup>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: 'black',
                                    width: '50%',
                                    color: 'white',
                                    ":hover": {
                                        backgroundColor: 'red',
                                        color: 'white',
                                        cursor: 'pointer'
                                    },
                                }} onClick={handleAddClose}>Cancel</Button>
                            <Button
                                sx={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    width: '50%',
                                    ":hover": {
                                        backgroundColor: '#50C878',
                                        color: 'white',
                                        cursor: 'pointer'
                                    },
                                }}
                                onClick={handleAddBrand}
                            >Add brand</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Box>
            <BrandTable brands={searchQuery ? searchResults : brands} />
        </Box>
    )
}

export default Brands
