import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loading from '../common/Loading'
import ModelTable from '../common/ModelTable'
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, TextField, DialogActions, DialogContent, DialogTitle, Dialog } from '@mui/material';
import DescriptionAlerts from '../common/Alert';
import { useNavigate } from 'react-router-dom';


const Models = () => {
    const { id, brandType } = useParams()
    const navigate = useNavigate()
    const [isFirstVisit, setIsFirstVisit] = useState(true)
    const [models, setModels] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [openAdd, setOpenAdd] = useState(false);
    const [modelName, setModelName] = useState('')
    const [modelImage, setModelImage] = useState('')
    const [parts, setParts] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    if (isFirstVisit) {
        window.scrollTo(0, 0);
        setIsFirstVisit(false)
    }

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        const searchQueryLowerCase = searchQuery.toLowerCase();
        // Filter models based on the search query
        const filteredModels = models.filter((model) =>
            model.name.toLowerCase().includes(searchQueryLowerCase)
        );

        setSearchResults(filteredModels);
    }, [searchQuery])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/model/getmodelbybrandid/${id}`)
            .then((response) => {
                setModels(response.data)
                setIsLoading(false)
            }).catch((err) => {
                setIsLoading(false)
                setSeverity('error')
                setAlertMessage('Something went wrong please try again')
                setOpenSnackbar(true);
            })
    })

    const handleAddOpen = () => {
        setOpenAdd(true)
    };

    const handleAddClose = () => {
        setOpenAdd(false);
        setModelImage('')
        setModelName('')
        setParts([])
    };

    const handleModelName = (e) => {
        setModelName(e.target.value)
    }

    const handleModelImage = (e) => {
        setModelImage(e.target.value)
    }

    const handleAddPart = () => {
        setParts([...parts, { name: '', price: 0, noOfStocks: 0 }]);
    };

    const handlePartNameChange = (index, value) => {
        const updatedParts = [...parts];
        updatedParts[index].name = value;
        setParts(updatedParts);
    };

    const handlePartPriceChange = (index, value) => {
        const updatedParts = [...parts];
        updatedParts[index].price = parseFloat(value, 10);
        setParts(updatedParts);
    };

    const handlePartQuantityChange = (index, value) => {
        const updatedParts = [...parts];
        updatedParts[index].noOfStocks = parseInt(value, 10);
        setParts(updatedParts);
    };

    const handleAddModel = () => {
        const token = localStorage.getItem('token')
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        axios.post(`${process.env.REACT_APP_API_URL}/model/addmodel`, {
            name: modelName,
            image: modelImage,
            brand: id,
            parts: parts
        }, { headers })
            .then((response) => {
                handleAddClose()
                setSeverity('success')
                setAlertMessage(response?.data?.message)
                setOpenSnackbar(true);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }).catch((err) => {
                console.log(err)
                setSeverity('error')
                setAlertMessage(err?.response?.data?.message)
                setOpenSnackbar(true);
                if (err?.response?.status === 401) {
                    setTimeout(() => {
                        navigate('/')
                    }, 1000);
                }
            })
    }

    if (isLoading) {
        return <Loading />
    }


    return (
        <Box sx={{ backgroundColor: '#eee', pt: '30px', pb: '30px' }}>
            <DescriptionAlerts openSnackbar={openSnackbar} handleCloseSnackbar={handleCloseSnackbar} severity={severity} alertMessage={alertMessage} />
            <Box sx={{ ml: '30px' }}>
                <Box sx={{ display: 'flex' }}>
                    <Button sx={{
                        backgroundColor: 'black', color: 'white',
                        ":hover": {
                            backgroundColor: '#50C878'
                        }
                    }}
                        onClick={handleAddOpen}>
                        <AddIcon />Add model
                    </Button>
                    <TextField
                        id="filled-search"
                        label="Search models..."
                        type="search"
                        variant="standard"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                        sx={{ backgroundColor: 'white', ml: '30px' }}
                    />
                </Box>
                <Box>
                    <Dialog open={openAdd} onClose={handleAddClose}>
                        <DialogTitle sx={{ backgroundColor: 'black', color: 'white', textAlign: 'center' }}>Add model</DialogTitle>
                        <DialogContent sx={{ mt: '10px' }}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="model name"
                                type="text"
                                fullWidth
                                variant="standard"
                                defaultValue={modelName}
                                onChange={handleModelName}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="image"
                                label="Image URL"
                                type="text"
                                fullWidth
                                variant="standard"
                                defaultValue={modelImage}
                                onChange={handleModelImage}
                            />
                            <Box>
                                <Button
                                    sx={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        width: '100%',
                                        mt: '10px',
                                        ":hover": {
                                            backgroundColor: '#50C878'
                                        }
                                    }}
                                    onClick={handleAddPart}><AddIcon />Add Part</Button>
                            </Box>
                            {parts.map((part, index) => (
                                <Box key={index}>
                                    <TextField
                                        sx={{ mt: '10px', mb: '10px', mr: '10px' }}
                                        type="text"
                                        placeholder='Part Name'
                                        value={part.name}
                                        onChange={(e) => handlePartNameChange(index, e.target.value)}
                                        required
                                    />
                                    <TextField
                                        sx={{ mt: '10px', mb: '10px' }}
                                        type="number"
                                        placeholder='Part Price'
                                        onChange={(e) => handlePartPriceChange(index, e.target.value)}
                                        required
                                    />
                                    <TextField
                                        sx={{ mt: '10px', mb: '10px' }}
                                        type="number"
                                        placeholder='Part Quantity'
                                        onChange={(e) => handlePartQuantityChange(index, e.target.value)}
                                        required
                                    />
                                </Box>
                            ))}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: 'black',
                                    width: '50%',
                                    color: 'white',
                                    ":hover": {
                                        backgroundColor: 'red',
                                        color: 'white',
                                        cursor: 'pointer'
                                    },
                                }} onClick={handleAddClose}>Cancel</Button>
                            <Button
                                sx={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    width: '50%',
                                    ":hover": {
                                        backgroundColor: '#50C878',
                                        color: 'white',
                                        cursor: 'pointer'
                                    },
                                }}
                                onClick={handleAddModel}
                            >Add model</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Box>

            <ModelTable models={searchQuery ? searchResults : models} brandType={brandType} />
        </Box>
    )
}

export default Models
