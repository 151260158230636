import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      letter:{
        main:'black'
      },
      navbar:{
        main:'white'
      },
      header:{
        main:'black',
        main1:'white',
      }

    },
  });


export default theme;