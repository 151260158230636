import React from 'react'
import theme from '../common/Theme';
import { Box, Divider, Grid, Typography, useTheme } from '@mui/material'
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MM from "../components/MM.png"
import { Height } from '@mui/icons-material';


const Footer = () => {

    return (
        <Box sx={{mt:'10px', pb:"10px"}}>
            {/* <Divider sx={{ width: '100%' }}/> */}
            <Box sx={{display:'flex', justifyContent:"center", alignItems:'center', }}>
            <Typography textTransform="uppercase">
                Copyright 2023 <img src={MM} alt="Copyright Image"/>. ªAll Rights are reserved to their respective owners.
            </Typography>
            </Box>
        </Box>
    )

    // const uitheme = useTheme()
    // const navigate = useNavigate()
    // const [email, setEmail] = useState('');
    // const [validEmail, setValidEmail] = useState(true);

    // const handleEmailChange = (event) => {
    //     setEmail(event.target.value);
    // };

    // const validateEmail = () => {
    //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //     const isValid = emailRegex.test(email);
    //     setValidEmail(isValid);
    // };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     validateEmail();
    //     if (validEmail) {
    //         // Perform further actions with the valid email
    //         console.log('Valid email:', email);
    //     }
    // };

    // const handleNav= (path)=>{
    //     navigate(path)
    // }




    // return (
    //     <Box>
    //         <Grid container spacing={2}  sx={{ justifyContent: 'center'}}>
    //             <Grid item xs={12} sm={4}>
    //             <Box sx={{ ml: '30px', mt: '30px' }}>
    //                 <Typography
    //                     variant="h4"
    //                     noWrap
    //                     component="a"
    //                     sx={{
    //                         fontFamily: 'Segoe UI',
    //                         fontWeight: '400',
    //                         color: theme.palette.letter.main,
    //                         textDecoration: 'none',
    //                     }}>
    //                     Shop Info
    //                 </Typography>
    //                 <Box sx={{
    //                     mt: '30px'
    //                 }}>
    //                     <Typography
    //                         variant='h6'
    //                         noWrap
    //                         component='a'
    //                         color='#666'
    //                         sx={{
    //                             ":hover": {
    //                                 cursor: 'pointer',
    //                                 ml: '20px',
    //                                 transition: 'margin-left 0.3s ease-in-out',
    //                             },
    //                             [uitheme.breakpoints.only('xs')]: {
    //                                 fontSize:'15px'
    //                               },
    //                         }}
    //                     >
    //                         <CallIcon sx={{ verticalAlign: 'middle', marginRight: '5px' }} /> +123 456 789
    //                     </Typography>
    //                 </Box>
    //                 <Box sx={{
    //                     mt: '10px'
    //                 }}>
    //                     <Typography
    //                         variant='h6'
    //                         noWrap
    //                         component='a'
    //                         color='#666'
    //                         sx={{
    //                             ":hover": {
    //                                 cursor: 'pointer',
    //                                 ml: '20px',
    //                                 transition: 'margin-left 0.3s ease-in-out',
    //                             },
    //                             [uitheme.breakpoints.only('xs')]: {
    //                                 fontSize:'15px'
    //                               },
    //                         }}
    //                     >
    //                         <EmailIcon sx={{ verticalAlign: 'middle', marginRight: '5px' }} /> example@gmail.com
    //                     </Typography>
    //                 </Box>
    //                 <Box sx={{
    //                     mt: '10px'
    //                 }}>
    //                     <Typography
    //                         variant='h6'
    //                         noWrap
    //                         component='a'
    //                         color='#666'
    //                         sx={{
    //                             ":hover": {
    //                                 cursor: 'pointer',
    //                                 ml: '20px',
    //                                 transition: 'margin-left 0.3s ease-in-out',
    //                             },
    //                             [uitheme.breakpoints.only('xs')]: {
    //                                 fontSize:'15px'
    //                               },
    //                         }}
    //                     >
    //                         <LocationOnIcon sx={{ verticalAlign: 'middle', marginRight: '5px' }} /> 10th lane, example, USA
    //                     </Typography>
    //                 </Box>
    //                 <Box sx={{
    //                     mt: '10px',
    //                     mb: '40px'
    //                 }}>
    //                     <Typography
    //                         variant='h6'
    //                         noWrap
    //                         component='a'
    //                         color='#666'
    //                         sx={{
    //                             ":hover": {
    //                                 cursor: 'pointer',
    //                                 ml: '20px',
    //                                 transition: 'margin-left 0.3s ease-in-out',
    //                             },
    //                             [uitheme.breakpoints.only('xs')]: {
    //                                 fontSize:'15px'
    //                               },
    //                         }}
    //                     >
    //                         <WatchLaterIcon sx={{ verticalAlign: 'middle', marginRight: '5px' }} /> Mon-fri(9am - 5pm)
    //                     </Typography>
    //                 </Box>
    //             </Box>
    //             </Grid>




    //             <Grid item xs={12} sm={4}>
    //             <Box sx={{ ml: '30px', mt: '30px' }}>
    //                 <Typography
    //                     variant="h4"
    //                     noWrap
    //                     component="a"
    //                     sx={{
    //                         fontFamily: 'Segoe UI',
    //                         fontWeight: '400',
    //                         color: theme.palette.letter.main,
    //                         textDecoration: 'none',
    //                     }}>
    //                     Quick Links
    //                 </Typography>
    //                 <Box sx={{
    //                     mt: '30px'
    //                 }}>
    //                     <Typography
    //                         variant='h6'
    //                         noWrap
    //                         component='a'
    //                         color='#666'
    //                         onClick={()=>handleNav('/home')}
    //                         sx={{
    //                             ":hover": {
    //                                 cursor: 'pointer',
    //                                 ml: '20px',
    //                                 transition: 'margin-left 0.3s ease-in-out',
    //                             },
    //                             [uitheme.breakpoints.only('xs')]: {
    //                                 fontSize:'15px'
    //                               },
    //                         }}
    //                     >
    //                         <ArrowForwardIcon sx={{ verticalAlign: 'middle', marginRight: '5px' }} /> Home
    //                     </Typography>
    //                 </Box>
    //                 <Box sx={{
    //                     mt: '10px'
    //                 }}>
    //                     <Typography
    //                         variant='h6'
    //                         noWrap
    //                         component='a'
    //                         color='#666'
    //                         onClick={()=>handleNav('/mobile')}
    //                         sx={{
    //                             ":hover": {
    //                                 cursor: 'pointer',
    //                                 ml: '20px',
    //                                 transition: 'margin-left 0.3s ease-in-out',
    //                             },
    //                             [uitheme.breakpoints.only('xs')]: {
    //                                 fontSize:'15px'
    //                               },
    //                         }}
    //                     >
    //                         <ArrowForwardIcon sx={{ verticalAlign: 'middle', marginRight: '5px' }} /> Mobile repair
    //                     </Typography>
    //                 </Box>
    //                 <Box sx={{
    //                     mt: '10px'
    //                 }}>
    //                     <Typography
    //                         variant='h6'
    //                         noWrap
    //                         component='a'
    //                         color='#666'
    //                         onClick={()=>handleNav('/tablet')}
    //                         sx={{
    //                             ":hover": {
    //                                 cursor: 'pointer',
    //                                 ml: '20px',
    //                                 transition: 'margin-left 0.3s ease-in-out',
    //                             },
    //                             [uitheme.breakpoints.only('xs')]: {
    //                                 fontSize:'15px'
    //                               },
    //                         }}
    //                     >
    //                         <ArrowForwardIcon sx={{ verticalAlign: 'middle', marginRight: '5px' }} /> Tablet repair
    //                     </Typography>
    //                 </Box>
    //                 <Box sx={{
    //                     mt: '10px',
    //                     mb: '40px'
    //                 }}>
    //                     <Typography
    //                         variant='h6'
    //                         noWrap
    //                         component='a'
    //                         color='#666'
    //                         onClick={()=>handleNav('/computer')}
    //                         sx={{
    //                             ":hover": {
    //                                 cursor: 'pointer',
    //                                 ml: '20px',
    //                                 transition: 'margin-left 0.3s ease-in-out',
    //                             },
    //                             [uitheme.breakpoints.only('xs')]: {
    //                                 fontSize:'15px'
    //                               },
    //                         }}
    //                     >
    //                         <ArrowForwardIcon sx={{ verticalAlign: 'middle', marginRight: '5px' }} /> Computer repair
    //                     </Typography>
    //                 </Box>
    //             </Box>
    //             </Grid>


    //             <Grid item xs={12} sm={4}>
    //             <Box sx={{ ml: '30px', mt: '30px', mb:'30px' }}>
    //                 <Box>
    //                     <Typography
    //                         variant="h4"
    //                         noWrap
    //                         component="a"
    //                         sx={{
    //                             fontFamily: 'Segoe UI',
    //                             fontWeight: '400',
    //                             color: theme.palette.letter.main,
    //                             textDecoration: 'none',
    //                         }}>
    //                         Newsletter
    //                     </Typography>
    //                 </Box>
    //                 <Box sx={{mt:'30px'}}>
    //                     <Typography
    //                         variant="h7"
    //                         noWrap
    //                         component="a"
    //                         sx={{
    //                             fontFamily: 'Segoe UI',
    //                             fontWeight: '325',
    //                             textDecoration: 'none',
    //                             display:'flex',
    //                             [uitheme.breakpoints.only('xs')]: {
    //                                 fontSize:'12px'
    //                               },

    //                         }}>
    //                         SignUp for regular newsletter and stay upto 
    //                     </Typography>
    //                 </Box>
    //                 <Box>
    //                     <Typography
    //                         variant="h7"
    //                         noWrap
    //                         component="a"
    //                         sx={{
    //                             fontFamily: 'Segoe UI',
    //                             fontWeight: '325',
    //                             color: theme.palette.letter.main,
    //                             textDecoration: 'none',
    //                             display:'flex',
    //                             [uitheme.breakpoints.only('xs')]: {
    //                                 fontSize:'12px'
    //                               },
    //                         }}>
    //                         date with our latest news
    //                     </Typography>
    //                 </Box>
    //                 <Box>
    //                     <form onSubmit={handleSubmit}>
    //                         <Box sx={{ display: 'flex', mt:'10px',mr:'15px' }}>
    //                             <Box >
    //                                 <TextField
    //                                     type="text"
    //                                     value={email}
    //                                     onChange={handleEmailChange}
    //                                     label="Email"
    //                                     error={!validEmail}
    //                                     helperText={!validEmail && 'Please enter a valid email address.'}
    //                                     sx={{

    //                                         '& .MuiOutlinedInput-root': {
    //                                             borderRadius: '10px 0 0 10px',
    //                                         },
    //                                         '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    //                                             borderColor: 'black',
    //                                         },
    //                                         '& .MuiFormLabel-root.Mui-focused': {
    //                                             color: 'black',
    //                                         },
    //                                     }}
    //                                     fullWidth
    //                                 />
    //                             </Box>
    //                             <Box>
    //                                 <Button
    //                                     type="submit"
    //                                     variant="contained"
    //                                     color="primary"

    //                                     sx={{
    //                                         backgroundColor: 'black',
    //                                         height: '56px',
    //                                         borderRadius: '0 10px 10px 0',
    //                                         '&:hover': {
    //                                             backgroundColor: '#666',

    //                                         },
    //                                     }}
    //                                     fullWidth
    //                                 >
    //                                     Subscribe
    //                                 </Button>
    //                             </Box>
    //                         </Box>
    //                     </form>
    //                 </Box>
    //             </Box>
    //             </Grid>
    //         </Grid>
    //     </Box>

    // )
}

export default Footer
