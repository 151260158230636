import React from 'react'
import { useParams } from 'react-router-dom'
import PartTable from '../common/PartsTable'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Loading from '../common/Loading';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, TextField, DialogActions, DialogContent, DialogTitle, Dialog } from '@mui/material';
import DescriptionAlerts from '../common/Alert';
import { useNavigate } from 'react-router-dom';


const Parts = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [isFirstVisit, setIsFirstVisit] = useState(true)
    const [model, setModel] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [openAdd, setOpenAdd] = useState(false);
    const [partName, setPartName] = useState('')
    const [partPrice, setPartPrice] = useState('')
    const [partQuantity, setPartQuantity] = useState('')
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('')
    const [alertMessage, setAlertMessage] = useState('')

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    if (isFirstVisit) {
        window.scrollTo(0, 0);
        setIsFirstVisit(false)
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/model/${id}`)
            .then((res) => {
                setModel(res.data)
                setIsLoading(false)
            }).catch((err) => {
                console.log(err)
                setIsLoading(false)
            })
    }, [id])

    const handleAddOpen = () => {
        setOpenAdd(true)
    };

    const handleAddClose = () => {
        setOpenAdd(false);
        setPartPrice('')
        setPartName('')
    };

    const handlePartName = (e) => {
        setPartName(e.target.value)
    }

    const handlePartPrice = (e) => {
        setPartPrice(e.target.value)
    }
    const handlePartQuantity = (e) => {
        setPartQuantity(e.target.value)
    }

    const handleAddPart = () => {
        const token = localStorage.getItem('token')
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        axios.post(`${process.env.REACT_APP_API_URL}/model/addpart/${model._id}`, {
            name: partName,
            price: parseInt(partPrice, 10),
            noOfStocks: partQuantity
        }, { headers })
            .then((response) => {
                console.log(response);
                handleAddClose();
                setSeverity('success')
                setAlertMessage('Part Added sucessfully')
                setOpenSnackbar(true);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
            .catch((err) => {
                console.log(err);
                setSeverity('error')
                setAlertMessage(err?.response?.data?.message)
                setOpenSnackbar(true);
                if (err?.response?.status === 401) {
                    setTimeout(() => {
                        navigate('/')
                    }, 1000);
                }
            });
    }

    if (isLoading) {
        return <Loading />
    }
    return (
        <Box sx={{ backgroundColor: '#eee', pt: '30px', pb: '30px' }}>
            <DescriptionAlerts openSnackbar={openSnackbar} handleCloseSnackbar={handleCloseSnackbar} severity={severity} alertMessage={alertMessage} />
            <Box sx={{ ml: '30px' }}>
                <Button sx={{
                    backgroundColor: 'black', color: 'white',
                    ":hover": {
                        backgroundColor: '#50C878'
                    }
                }}
                    onClick={handleAddOpen}>
                    <AddIcon />Add Part
                </Button>

                <Box>
                    <Dialog open={openAdd} onClose={handleAddClose}>
                        <DialogTitle sx={{ backgroundColor: 'black', color: 'white', textAlign: 'center' }}>Add Part</DialogTitle>
                        <DialogContent sx={{ mt: '10px' }}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Part name"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={handlePartName}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="price"
                                label="Part price"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={handlePartPrice}
                            />
                            <TextField
                                autoFocus
                                margin="dense"
                                id="price"
                                label="Part Quantity"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={handlePartQuantity}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: 'black',
                                    width: '50%',
                                    color: 'white',
                                    ":hover": {
                                        backgroundColor: 'red',
                                        color: 'white',
                                        cursor: 'pointer'
                                    },
                                }} onClick={handleAddClose}>Cancel</Button>
                            <Button
                                sx={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    width: '50%',
                                    ":hover": {
                                        backgroundColor: '#50C878',
                                        color: 'white',
                                        cursor: 'pointer'
                                    },
                                }}
                                onClick={handleAddPart}
                            >Add Part</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Box>
            <PartTable model={model} />
        </Box>

    )
}

export default Parts
