import axios from 'axios';
import Loading from '../common/Loading';
import { useEffect, useState } from 'react';
import ApplicationTable from '../common/ApplicationTable';
import DescriptionAlerts from '../common/Alert';
import { useNavigate } from 'react-router-dom';
import { TextField, Box } from '@mui/material';

function Home() {

  const navigate = useNavigate()
  const [isFirstVisit, setIsFirstVisit] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [applications, setApplication] = useState([])
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };


  if (isFirstVisit) {
    window.scrollTo(0, 0);
    setIsFirstVisit(false)
  }

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const searchQueryLowerCase = searchQuery.toLowerCase();
    // Filter applications based on the search query
    const filteredBrands = applications.filter((application) =>
      application.email.toLowerCase().includes(searchQueryLowerCase) ||
      application.modelName.toLowerCase().includes(searchQueryLowerCase)
    );
    setSearchResults(filteredBrands);
  }, [searchQuery])

  useEffect(() => {
    const token = localStorage.getItem('token')
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };


    setTimeout(function () {
      axios.get(`${process.env.REACT_APP_API_URL}/application/`, { headers })
        .then((res) => {
          setApplication(res.data)
          setIsLoading(false)
        }).catch((err) => {
          setIsLoading(false)
          setSeverity('error')
          setAlertMessage(err?.response?.data?.message)
          setOpenSnackbar(true);
          if (err?.response?.status === 401) {
            navigate('/')
          }
        })
    }, 5000);
  })

  if (isLoading) {
    return <Loading />
  }
  return (
    <Box sx={{ backgroundColor: '#eee', pt: '30px', pb: '30px' }}>
      <DescriptionAlerts openSnackbar={openSnackbar} handleCloseSnackbar={handleCloseSnackbar} severity={severity} alertMessage={alertMessage} />
      <TextField
        id="filled-search"
        label="Search applications by EM or MN"
        type="search"
        variant="standard"
        value={searchQuery}
        onChange={handleSearchInputChange}
        sx={{ backgroundColor: 'white', mt: "30px", ml: '30px' }}
      />
      <ApplicationTable applications={searchQuery ? searchResults : applications} />
    </Box>
  )
}

export default Home
