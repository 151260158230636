import { Box, Button, TextField, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DescriptionAlerts from '../common/Alert';


const Login = () => {
    
    const navigate = useNavigate()
    const [ isFirstVisit, setIsFirstVisit] = useState(true)
    const uitheme = useTheme()
    const [username,setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('')
    const [alertMessage, setAlertMessage] = useState('')

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
      };
    
      if (isFirstVisit) {
        window.scrollTo(0, 0);
        setIsFirstVisit(false)
    }

    const handleUsername=(e)=>{
        setUsername(e.target.value)
    }

    const handlePassword = (e) =>{
        setPassword(e.target.value)
    }

    const handleLogin =()=>{
        axios.post(`${process.env.REACT_APP_API_URL}/auth/login`,{
            name:username,
            password:password
        }).then((response)=>{
            localStorage.setItem('token',response.data)
            navigate('/home')

        }).catch((err)=>{
            console.log(err)
            setSeverity('error')
            setAlertMessage(err?.response?.data?.message)
            setOpenSnackbar(true);
        })
    }

    return (
        <Box sx={{
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
        }}>
            <DescriptionAlerts openSnackbar={openSnackbar} handleCloseSnackbar={handleCloseSnackbar} severity={severity} alertMessage={alertMessage} />
            <Box sx={{
                backgroundColor: '#eee',
                width: '30%',
                height: '60%',
                [uitheme.breakpoints.only('xs')]:{
                    width: '80%',
                    height: '50%',
                },
                borderRadius: '10px'
            }}>
                <Box>
                    <Box sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        pt: '50px'
                    }}>
                        <Avatar
                            alt=""
                            sx={{ width: 90, height: 90, backgroundColor:'black', position:'top' }}
                        />
                    </Box>
                    <Box sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                        <Typography
                            variant='h5'
                            fontFamily='sans-serif'
                            sx={{fontWeight:300}}
                        >
                            Welcome
                        </Typography>
                    </Box>
                    <Box sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                        <TextField
                            type='text'
                            label='Username'
                            variant="standard"
                            onChange={handleUsername}
                            sx={{ mt: '30px', width: '80%',
                         }}
                        />
                    </Box>
                    <Box sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                        <TextField
                            type='password'
                            label='Password'
                            variant="standard"
                            onChange={handlePassword}
                            sx={{ mt: '15px', width: '80%',
                         }}
                        />
                    </Box>
                    <Box sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                        <Button sx={{
                            width: '80%',
                            borderRadius:'20px',
                            mt: '30px', backgroundColor: 'black',
                            ":hover": {
                                backgroundColor: '#50C878',
                            },
                            color: 'white',
                        }}
                        onClick={handleLogin}
                        > Login
                        </Button>
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}

export default Login
