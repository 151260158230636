import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, Box, TextField, DialogActions, Button, DialogContent, DialogContentText, DialogTitle, Dialog, RadioGroup, FormControlLabel, FormLabel, FormControl, Radio } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DescriptionAlerts from './Alert';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const BrandTable = ({ brands }) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [editBrandId, setEditBrandId] = useState('')
    const [updatedName, setUpdatedName] = useState('')
    const [updatedImage, setUpdatedImage] = useState('')
    const [deleteId, setDeleteId] = useState('')
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [newBrandType, setNewBrandType] = useState('')

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const editButtonClicked = (brand) => {
        setEditBrandId(brand._id)
        setUpdatedName(brand.name)
        setUpdatedImage(brand.image)
        setNewBrandType(brand.brandType)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEditBrandId('')
        setUpdatedName('')
        setUpdatedImage('')
        setNewBrandType('')
    };

    const handleOpenDelete = () => {
        setDeleteDialogOpen(true)
    }

    const handleCloseDelete = () => {
        setDeleteDialogOpen(false)
        setDeleteId('')
    }

    const deleteButtonClicked = (id) => {
        setDeleteId(id)
    }

    useEffect(() => {
        if (deleteId) {
            handleOpenDelete()
        }
    }, [deleteId])

    useEffect(() => {
        if (editBrandId) {
            handleClickOpen();
        }
    }, [editBrandId]);

    const handleBrandName = (e) => {
        setUpdatedName(e.target.value)
    }

    const handleBrandImage = (e) => {
        setUpdatedImage(e.target.value)
    }

    const handleBrandType = (e) => {
        setNewBrandType(e.target.value)
    }

    const handleUpdate = () => {
        const token = localStorage.getItem('token')
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        axios.put(`${process.env.REACT_APP_API_URL}/brand/updatebrand`, {
            id: editBrandId,
            name: updatedName,
            image: updatedImage,
            brandType: newBrandType
        }, { headers })
            .then((response) => {
                console.log(response)
                setEditBrandId('')
                setUpdatedImage('')
                setUpdatedName('')
                handleClose()
                setSeverity('success')
                setAlertMessage(response.data.message)
                setOpenSnackbar(true);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }).catch((err) => {
                console.log(err)
                setSeverity('error')
                setAlertMessage(err?.response?.data?.message)
                setOpenSnackbar(true);
                if (err?.response?.status === 401) {
                    setTimeout(() => {
                        navigate('/')
                    }, 1000);
                }
            })
    }

    const handleDelete = () => {
        const token = localStorage.getItem('token')
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        axios.delete(`${process.env.REACT_APP_API_URL}/brand/deletebrand/${deleteId}`, { headers })
            .then((response) => {
                setDeleteId(false)
                handleCloseDelete()
                setSeverity('success')
                setAlertMessage('Brand deleted sucessfully')
                setOpenSnackbar(true);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }).catch((err) => {
                console.log(err)
                setSeverity('error')
                setAlertMessage(err?.response?.data?.message)
                setOpenSnackbar(true);
                if (err?.response?.status === 401) {
                    setTimeout(() => {
                        navigate('/')
                    }, 1000);
                }
            })
    }

    const displayModels = (id, brandType) => {
        navigate(`/model/${id}/${brandType}`)
    }
    return (
        <Box>
            <DescriptionAlerts openSnackbar={openSnackbar} handleCloseSnackbar={handleCloseSnackbar} severity={severity} alertMessage={alertMessage} />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '30px', mb: '30px', ml: '30px', mr: '30px' }}>
                <TableContainer component={Paper} align='center'>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Brand Name</StyledTableCell>
                                <StyledTableCell align="left">Brand Image</StyledTableCell>
                                <StyledTableCell align="left">models</StyledTableCell>
                                <StyledTableCell align="left">Edit</StyledTableCell>
                                <StyledTableCell align="letf">Delete</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {brands.map((brand) => (
                                <StyledTableRow key={brand._id} sx={{ ":hover": { backgroundColor: '#666' } }}>
                                    <StyledTableCell component="th" scope="row">
                                        {brand.name}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ height: '20%', width: '40%' }} align="left" >
                                        <Avatar
                                            alt=""
                                            src={brand.image}
                                            sx={{ width: 90, height: 90, backgroundColor: 'black', position: 'top' }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="left"><ReadMoreIcon sx={{
                                        borderRadius: '50%',
                                        width: '40px',
                                        height: '30px',
                                        ":hover": {
                                            backgroundColor: 'black',
                                            color: 'white'
                                        }
                                    }} onClick={() => displayModels(brand._id, brand.brandType)} /></StyledTableCell>
                                    <StyledTableCell align="left"><EditIcon sx={{
                                        borderRadius: '50%',
                                        width: '40px',
                                        height: '30px',
                                        ":hover": {
                                            backgroundColor: 'black',
                                            color: 'white'
                                        }
                                    }} onClick={() => editButtonClicked(brand)} /></StyledTableCell>
                                    <StyledTableCell align="left"><DeleteIcon sx={{
                                        borderRadius: '50%',
                                        width: '40px',
                                        height: '30px',
                                        ":hover": {
                                            backgroundColor: 'black',
                                            color: 'white'
                                        }
                                    }} onClick={() => deleteButtonClicked(brand._id)} /></StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>



            <Box>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle sx={{ backgroundColor: 'black', color: 'white', textAlign: 'center' }}>Update brand</DialogTitle>
                    <DialogContent sx={{ mt: '10px' }}>
                        <DialogContentText >
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Avatar
                                    alt=""
                                    src={updatedImage}
                                    sx={{ width: 90, height: 90, backgroundColor: 'black', position: 'top' }}
                                />
                            </Box>
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="brand name"
                            type="text"
                            fullWidth
                            variant="standard"
                            defaultValue={updatedName}
                            onChange={handleBrandName}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="email"
                            label="Image URL"
                            type="text"
                            fullWidth
                            variant="standard"
                            defaultValue={updatedImage}
                            onChange={handleBrandImage}
                        />
                        <FormControl sx={{ mt: '10px' }}>
                            <FormLabel id="demo-radio-buttons-group-label">Type</FormLabel>
                            <RadioGroup
                                onChange={handleBrandType}
                            >
                                <FormControlLabel value={'mobile'} control={<Radio />} label='Mobile' />
                                <FormControlLabel value={'tablet'} control={<Radio />} label='Tablet' />
                                <FormControlLabel value={'ipad'} control={<Radio />} label='I Pad' />
                                <FormControlLabel value={'laptop'} control={<Radio />} label='Laptop' />
                                <FormControlLabel value={'computer'} control={<Radio />} label='Computer' />
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={{
                                backgroundColor: 'black',
                                width: '50%',
                                color: 'white',
                                ":hover": {
                                    backgroundColor: 'red',
                                    color: 'white',
                                    cursor: 'pointer'
                                },
                            }} onClick={handleClose}>Cancel</Button>
                        <Button
                            sx={{
                                backgroundColor: 'black',
                                color: 'white',
                                width: '50%',
                                ":hover": {
                                    backgroundColor: '#50C878',
                                    color: 'white',
                                    cursor: 'pointer'
                                },
                            }}
                            onClick={handleUpdate}
                        >Update</Button>
                    </DialogActions>
                </Dialog>
            </Box>


            <Box>
                <Dialog open={deleteDialogOpen} onClose={handleCloseDelete}>
                    <DialogContent sx={{ mt: '10px', }}>
                        <DialogContentText >
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                            </Box>
                            Are you sure!
                            Do you want to delete it?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            sx={{
                                backgroundColor: 'black',
                                width: '50%',
                                color: 'white',
                                ":hover": {
                                    backgroundColor: 'red',
                                    color: 'white',
                                    cursor: 'pointer'
                                },
                            }} onClick={handleCloseDelete}>No! Don't</Button>
                        <Button
                            sx={{
                                backgroundColor: 'black',
                                color: 'white',
                                width: '50%',
                                ":hover": {
                                    backgroundColor: '#50C878',
                                    color: 'white',
                                    cursor: 'pointer'
                                },
                            }}
                            onClick={handleDelete}
                        >yes, please</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

export default BrandTable