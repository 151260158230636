import { useState } from "react";

const ContactUs = () => {
  const [ isFirstVisit, setIsFirstVisit] = useState(true)

    
  if (isFirstVisit) {
      window.scrollTo(0, 0);
      setIsFirstVisit(false)
  }

  return (
    <div>
      contact us page just to ckeck
    </div>
  )
}

export default ContactUs
